
import data from '@/data/co.data'
import mixBannerBase from '@/pages/mixins/mix.banner.base'

export default {
  mixins: [mixBannerBase],
  data () {
    return {
    }
  },
  created () {
    this.navSelf.text = '解决方案'
    this.navSelf.href = '/scheme'
    this.classify = {
      height: 480,
      points: {
        xs: 200,
        sm: 320,
        md: 410,
        lg: 480,
        xl: 480
      }
    }
    this.banItems = data.banner.scheme
  },
  methods: {
  }
}
