<template>
  <section>
    <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
    />

    <land-intro-image-text
      :back-color="intro1.backColor"
      :assist-color="intro1.assistColor"
      :header="intro1.header"
      :content="intro1.content"
      @action="onAction(intro1)"
    />
    <land-intro-image-text
      :back-color="intro2.backColor"
      :assist-color="intro2.assistColor"
      :header="intro2.header"
      :content="intro2.content"
      @action="onAction(intro2)"
    />
    <land-intro-image-text
      :back-color="intro3.backColor"
      :assist-color="intro3.assistColor"
      :header="intro3.header"
      :content="intro3.content"
      @action="onAction(intro3)"
    />
    <land-intro-icons-ver
      :back-color="jiuDian.backColor"
      :assist-color="jiuDian.assistColor"
      :header="jiuDian.header"
      :features="jiuDian.features"
      :button="jiuDian.button"
    />
    <land-intro-image-text
      :back-color="intro4.backColor"
      :assist-color="intro4.assistColor"
      :header="intro4.header"
      :content="intro4.content"
      @action="onAction(intro4)"
    />
    <land-intro-icons-ver
      :back-color="weiSheng.backColor"
      :assist-color="weiSheng.assistColor"
      :header="weiSheng.header"
      :features="weiSheng.features"
      :button="weiSheng.button"
    />
    <section-foot />
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import data from '@/data/co.data'
  import mixSchemeBanner from '@/pages/mixins/scheme/mix.scheme.banner'

  export default {
    components: {
      SectionFoot: () => import('@/pages/sections/Foot.vue')
    },
    metaInfo: { title: '解决方案' },
    mixins: [
      mixSchemeBanner
    ],
    data () {
      return {
        userInfo: {},
        intro1: data.scheme.resumes.ZhiZaoYe,
        intro2: data.scheme.resumes.HuaGong,
        intro3: data.scheme.resumes.WeiHuaPin,
        jiuDian: data.scheme.resumes.JiuDian,
        intro4: data.scheme.resumes.NongYe,
        weiSheng: data.scheme.resumes.WeiSheng,
      }
    },
    created () {
    },
    methods: {
      onAction (ev) {
        const params = {
          contentId: ev.contentId,
        }

        api.page.navigateBy(this, 'schemeDetail', params)
      },
    }
  }
</script>
